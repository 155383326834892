/* General container styling for the success page */
.managers-payment-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative; /* For confetti effect positioning */
  background: 
    url('../assets/cubes.png'), /* Local path to cubes.png */
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  background-size:auto; /* Ensure the image covers the entire container */
}

/* Header container styling */
.managers-payment-header {
  text-align: center;
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

/* Loading spinner */
.ManagerPaymentSuccess-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #555555;
}

/* Title styling */
.ManagerPaymentSuccess-title {
  font-size: 2.5rem;
  color: #4caf50;
  margin-bottom: 20px !important;
}

/* Description text styling */
.ManagerPaymentSuccess-text {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 30px !important;
  text-align: left;
}

/* Button container styling */
.ManagerPaymentSuccess-button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Button styling */
.ManagerPaymentSuccess-button {
  padding: 1rem 2rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.ManagerPaymentSuccess-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slightly grow the button on hover */
}

/* Focus state for accessibility */
.ManagerPaymentSuccess-button:focus {
  outline: 2px solid #ffcc00; /* Add a yellow outline for focus state */
  outline-offset: 4px;
}

/* Success animation styling */
#confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999; /* Ensure confetti is above other elements */
}

/* Responsive layout adjustments */
@media (max-width: 768px) {
  .ManagerPaymentSuccess-title {
      font-size: 2rem;
  }

  .ManagerPaymentSuccess-text {
      font-size: 1rem;
  }

  .ManagerPaymentSuccess-button {
      font-size: 1rem;
      padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .managers-payment-header {
      padding: 2rem;
  }

  .ManagerPaymentSuccess-button-container {
      gap: 0.5rem;
  }

  .ManagerPaymentSuccess-button {
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
  }
}
