.drop-drag-game-container {
  padding: 2rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1200px;
  text-align: center;
  position: relative; /* Added for positioning */
}

/* Back arrow button styling at the top left */
.back-arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-arrow-button:hover {
  color: #005f73;
}

.terms-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.term {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white !important; /* Set the text color to white */
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
}

.categories-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.category {
  flex: 1;
  min-width: 200px;
  padding: 1rem;
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  background-color: var(--light-background);
  min-height: 150px;
}

.category h3 {
  margin-bottom: 1rem;
}

.game-complete {
  margin-top: 2rem;
}

.game-complete button {
  padding: 0.75rem 1.5rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.game-complete button:hover {
  background-color: rgb(37, 138, 171);
}
