/* General container styling */
.FinishersPageTwo-introduction-container {
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    background: 
    linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
    min-height: 100vh; /* Full height of the screen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Story introduction section */
.FinishersPageTwo-story-introduction {
    background-color:#005f73;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    
}

/* Introduction content */
.FinishersPageTwo-introduction-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FinishersPageTwo-story-introduction h2 {
color: rgb(246, 246, 246);
margin-bottom: 20px;
}

/* Text styling */
.FinishersPageTwo-introduction-text p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: white;
    margin-bottom: 20px;
    text-align: left;
}

/* Button styling */
.FinishersPageTwo-start-button {
    background-color:rgb(250, 74, 55);
    color:black;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.FinishersPageTwo-start-button:hover {
    background-color: rgb(255, 177, 169);
}

/* Responsive styling for mobile and tablets */
@media (max-width: 768px) {
    .FinishersPageTwo-story-introduction {
        padding: 15px;
    }

    .FinishersPageTwo-introduction-text p {
        font-size: 1rem;
        line-height: 1.5;
    }

    .FinishersPageTwo-start-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .FinishersPageTwo-story-introduction {
        padding: 10px;
    }

    .FinishersPageTwo-introduction-text p {
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .FinishersPageTwo-start-button {
        padding: 6px 12px;
        font-size: 12px;
    }
}
