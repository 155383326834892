.cybersecurity-basics-container-r {
  padding: 2rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1000px;
  text-align: left;
  position: relative;
  
}

.audio-controls {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.audio-controls button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.audio-controls button:hover {
  background-color: var(--hover-color);
}

.module-section {
  margin-bottom: 2rem;
}




.module-section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.module-content {
  font-size: 1.3rem !important; /* Ensure this matches with RecognizingThreats */
  color: white;
  margin-bottom: 1rem;
}

.thumbnail {
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.bordered-container {
  border: 2px solid black;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-item.image {
  align-items: center;
}

.grid-item.text {
  align-items: flex-start;
}

.key-points {
  border: 2px solid salmon;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.key-points h3 {
  color: salmon;
}

.message {
  text-align: center;
  margin: 1.5rem 0;
}

.message h3 {
  font-size: 1.5rem;
  color: rgb(252, 154, 143);
}

.quiz-section {
  margin-top: 1rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
}

.quiz-section h4 {
  font-size: 1.25rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.quiz-section button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quiz-section button:hover {
  background-color: var(--hover-color);
}

.correct {
  color: green;
  font-weight: bold;
}

.incorrect {
  color: red;
  font-weight: bold;
}

.next-quiz-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.next-quiz-button:hover {
  background-color: var(--hover-color);
}

.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
}

.back-button {
  padding: 0.5rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem !important;
}

.back-button:hover {
  background-color: var(--hover-color);
}

.quiz-back-button-container {
  display: flex;
  justify-content: flex-start; /* Align the button to the left */
  margin-bottom: 1rem; /* Space between button and the quiz content */
}

.quiz-back-button {
  padding: 0.5rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.quiz-back-button:hover {
  background-color: var(--hover-color);
}

.hide-content .module-section,
.hide-content .back-button-container,
.hide-content .audio-controls {
  display: none;
}

.next-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}