/* General footer styles */
.footer-container {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer-content {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

/* Sitemap styles */
.sitemap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sitemap h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ff6347;
}

.sitemap-links {
  list-style-type: none;
  padding: 0;
}

.sitemap-link1,
.sitemap-link2,
.sitemap-link3 {
  color: #ff9f8f;
  text-decoration: none;
  font-size: larger;
  margin-bottom: 20px;
}



.sitemap-link1:hover,
.sitemap-link2:hover,
.sitemap-link3:hover {
  color: #ff9f8f;
}

/* Vertical Separator */
.footer-separator {
  width: 2px;
  height: 100px;
  background-color: #fff;
  margin: 0 30px;
  display: inline-block;
}

/* Legal section styles */
.legal {
  flex: 3;
  text-align: left;
  padding-left: 10px;
}

.legal p {
  font-size: 0.9rem;
  margin-bottom: 10px;
  line-height: 1.5;
}

.footer-links {
  margin-top: 10px;
 
  margin-bottom: 10px;
 
}

.footer-link {
  color: #ff9f8f;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-separator {
    display: none; /* Hide separator on smaller screens */
  }

  .sitemap,
  .legal {
    flex: 1;
    margin: 0;
  }

  .legal {
    padding-left: 0;
  }

  /* Add horizontal line after About link only on smaller screens */
  .sitemap-links::after {
    content: '';
    width: 90%;
    height: 1px;
    background-color: #fff;
    margin: 20px auto;
    display: block; /* Horizontal line appears only on smaller screens */
  }

  .sitemap-links li {
    margin-bottom: 20px;
  }
}

/* Hide the horizontal line on larger screens */
@media (min-width: 769px) {
  .sitemap-links::after {
    display: none; /* Hide horizontal line on larger screens */
  }
}
