/* Cookie Consent Banner */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.cookie-consent-banner p {
  margin: 0;
}

.cookie-consent-banner a {
  color: #007bff;
  text-decoration: underline;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
}

.cookie-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-buttons button:hover {
  opacity: 0.9;
}

.cookie-buttons button:nth-child(1) {
  background-color: #007bff;
  color: white;
}

.cookie-buttons button:nth-child(2) {
  background-color: #ff4d4d;
  color: white;
}

.cookie-buttons button:nth-child(3) {
  background-color: rgb(82, 210, 82);
  color: white;
}

/* Cookie Preferences Modal */
.cookie-consent-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  width: 600px;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.modal-content {
  text-align: left;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.preference-item {
  margin-bottom: 15px;
}

.preference-item h4 {
  margin: 0 0 5px;
}

.preference-item p {
  margin: 0 0 10px;
}
