.cybersecurity-basics-container {
    padding: 2rem;
    background-color: var(--light-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 2rem auto;
    max-width: 1000px;
    text-align: left;
    position: relative;
}

.module-section2 {
    margin-bottom: 2rem;
}

.module-section2 h2 {
    font-size: 1.5rem !important;
    color: var(--primary-color);
    margin-bottom: 1rem;
    margin-top: 3rem;
    text-align: center; /* ✅ Centers text horizontally */
    display: flex;
    justify-content: center; /* ✅ Centers within a flex container */
}


.module-content2 {
    font-size: 1.25rem;
    color: black;
    margin-bottom: 1rem;
}

.thumbnail2 {
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.bordered-container2 {
    border: 2px solid black;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: var(--text-color);
}

.task-instruction {
    text-align: center;
    width: 100%;
}

.grid-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.grid-item-text2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-right: 0.4rem;
}

.grid-item-image2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 1.25rem;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.next-button:hover {
    background-color: rgb(37, 138, 171);
}

.back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem !important;
}

.back-button {
    padding: 0.5rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: rgb(37, 138, 171) !important;
}

.email-example-box {
    border: 2px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 1rem;
}

.email-content p {
    margin: 0.5rem 0;
}

.email-content a {
    color: blue; /* Set the link color to blue */
}

.answer-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.answer-buttons2 {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    color: #ddd;
}

.answer-buttons button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.answer-buttons button:hover {
    background-color: rgb(37, 138, 171);
}

.feedback {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.feedback.correct {
    color: green;
}

.feedback.incorrect {
    color: rgb(155, 0, 0);
}

.phishing-signs {
    margin-top: 2rem;
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.quiz-question {
    margin-bottom: 1rem;
}

.quiz-question p {
    margin-bottom: 2rem;
}

.quiz-results {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.quiz-buttons {
    text-align: center;
    margin-top: 2rem;
}
.quiz-option {
    margin-bottom: 1rem; /* Add more space between options */
    
}


.submit-button,
.retake-button,
.next-button {
    padding: 0.5rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin: 0.5rem;
}

.submit-button:hover,
.retake-button:hover,
.next-button:hover {
    background-color: rgb(37, 138, 171);
}

/* Tooltip container */
.tooltip-link {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  /* Tooltip text */
  .tooltip-link .tooltip-text {
    visibility: hidden;
    width: auto; /* Set width to auto */
    max-width: 300px; /* Add max-width if necessary */
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px; /* Add padding for better coverage */
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the link */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip-link .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when hovering over the tooltip container */
  .tooltip-link:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  