/* src/App.css */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content {
  display: flex;
  padding-top: 0; /* Ensure it starts below the top bar */
}

.content-with-sidebar {
  margin-left: 0px; /* Same as sidebar width */
  flex: 1;
  padding: 1rem;
  background: url('../src/assets/cubes.png'), linear-gradient(90deg, black 20%, black 80%);
  min-height: calc(100vh - 6rem); /* Ensure it covers the full height minus the top bar */
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it fills at least the viewport height */
}

.content {
  flex: 1; /* Makes it expand within the parent */
  padding: 0;
  margin: 0;
  background: 
  url('../src/assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, black 20%, black); /* Gradual two-tone background */
  min-height: 100vh; /* Ensures it fills the full viewport height */
  box-sizing: border-box;
}

