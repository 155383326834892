:root {
  --sidebar-width: 250px; /* Adjust as needed */
  --sidebar-bg: #2c3e50; /* Sidebar background color */
  --sidebar-text: #ecf0f1; /* Sidebar text color */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: row; /* Ensure main layout is row-based */
  min-height: 100vh; /* Ensure it stretches full height */
}

.sidebar4 {
  width: 150px;
  height: var(--sidebar-height); /* Use CSS variable for height */
  background: #ffeadf;
  color: var(--white);
  position: relative;
  top: 0rem; /* Adjust based on your top bar height */
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 1rem;
  border-left: 1px solid grey; /* Thin grey line on the left */
  z-index: 1000; /* Ensure it is on top of other content */
  transition: transform 0.3s ease-in-out;
}

/* Sidebar Hidden for Small Screens */
.sidebar4-hidden {
  transform: translateX(-190px); /* Move it off-screen */
}

/* Sidebar Toggle Button */
.sidebar4-toggle-btn {
  position: absolute;
  bottom: 0px; /* Stick to the bottom of the sidebar */
  left: 0;
  background-color: #0a9396;
  color: white;
  padding: 10px;
  transform: rotate(-90deg); /* Rotate the text vertically */
  transform-origin: left top;
  cursor: pointer;
  font-size: 16px;
  z-index: 2000;
  display: none; /* Hidden by default for large screens */
}

@media (max-width: 768px) {
  /* For small screens, show the toggle button */
  .sidebar4-toggle-btn {
    display: block; /* Show button */
    padding: 1px;
  }

  .sidebar4 {
    position: fixed; /* Sidebar is fixed on small screens */
    top: 58px;
    left: 0;
    height: 60vh; /* Full viewport height */
    display: none; /* Hide sidebar initially */
  }

  .sidebar4.show {
    display: block; /* Slide the sidebar in when shown */
    transform: translateX(0);
  }
}

.sidebar4-ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.sidebar4-li {
  list-style-type: none; /* Remove bullet points from list items */
  margin: 1.5rem 0; /* Increase space between each list item */
}

.sidebar4-li:hover {
  color: var(--hover-color);
  cursor: pointer;
}

.sidebar4 a {
  color: black;
  text-decoration: none;
}

.sidebar4 a:hover {
  color: salmon;
}

/* Style for active link */
.sidebar4 a.active4 {
  color: salmon;
  font-weight: bolder;
  
  padding: 0rem; /* Optional padding for better visibility */
}
/* Custom Scroll Bar for Sidebar */
.sidebar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track corners */
}

.sidebar::-webkit-scrollbar-thumb {
  background: #0a9396; /* Scroll thumb color */
  border-radius: 10px; /* Rounded scroll thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #005f73; /* Change color when hovered */
}

/* Firefox scrollbar styles */
.sidebar {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #0a9396 #f1f1f1; /* Scrollbar thumb and track colors */
}