

.reset-password-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    background: 
    url('../assets/cubes.png'), /* Local path to cubes.png */
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: larger;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .reset-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .reset-password-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  
  .reset-button:hover {
    background-color: rgb(61, 54, 54);
  }
  
  .success-message {
    color: white;
    font-weight: bold;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
 
  .reset-message {
    color: white;
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .reset-error {
    color: white;
    font-size: 16px;
    text-align: center;
  }
  
  .email-label {
    color: white;
  }
  