/* EmailVerification.css */

.verification-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: aliceblue;
    padding: 20px;
    box-sizing: border-box;
    background: 
    url('../assets/cubes.png'), /* Local path to cubes.png */
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  }
  
  .verification-container h1 {
    color: white;
    font-size: 2rem;
    margin-bottom: 20px !important;
  }
  
  .verification-container p {
    font-size: 1.2rem;
    color: #555;
    text-align: center;
    max-width: 800px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  /* Add a subtle animation to the verification message */
  .verification-container p {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .verification-container h1 {
      font-size: 1.5rem;
    }
  
    .verification-container p {
      font-size: 1rem;
      padding: 10px;
    }
  }
  
  /* Typography settings for a cleaner look */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffeadf;
  }
  
  .verification-button {
    padding: 1.5rem 2.2rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
  }

  .resend-button {
    padding: 1.5rem 2.2rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  .verification-button:hover,
.resend-button:hover {
    background-color: rgb(67, 67, 67);
    color: white; /* Optional: Ensures text is visible on black background */
}
