:root {
  --primary-color: #005f73;
  --light-background: #f4f4f9;
  --hover-color: #0a9396;
  --white: #ffffff;
  --dark-text: #333333;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 
  padding: 1rem;
  background: 
  url('../assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
}

.signup-box {
  background-color: var(--white);
  border-radius: 8px;
  height: 650px;
  padding: 40px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.back-button-container-signup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0px;
}

.back-button-signup {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: 40px;
 
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-button-signup:hover {
  background-color: var(--hover-color);
 
}

.password-container {
  position: relative;
}

.password-container input {
  width: 100%;
  padding-right: 40px; /* Add space for the icon */
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}


/* Remaining CSS stays the same */


.signup-box h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group-signup label {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: var(--dark-text);
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.button-su { 
  margin-top: 5px !important;
}

.error-text {
  color: rgb(132, 0, 0);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
}

.success-text {
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 19px;
  margin-bottom: 20px;
  font-weight: bolder;
}


.login-button {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.login-button:hover {
  background-color: var(--hover-color);
  
}

@media (max-width: 768px) {
  .signup-box {
    padding: 30px;
    max-width: 350px;
  }

  .back-button {
    padding: 8px 16px;
  }

  .login-button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .signup-box {
    padding: 20px;
    max-width: 300px;
  }

  .back-button {
    padding: 6px 12px;
  }

  .login-button {
    padding: 6px 12px;
  }
}
