
/* General reset for margins and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: aliceblue; /* Your background color */
  }
  
  /* Main container styling to avoid margins */
  .main-container-content-about {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  

  
  .section-1-about {
    margin: 0;
    padding: 0;
    background: 
    url('https://www.transparenttextures.com/patterns/cubes.png'),
    linear-gradient(90deg, black 20%, black);
    
  }
  
  /* SVG Section */
  .svg-section-1-about {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: -4px;
  }
  

  
  /* Second Section: Courses */
  .section-2-about {
    background-color: #ffeadf;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .content-wrapper-about {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    text-align: center;
  }

  .content-container-about1 {
    flex: 1;
    padding-right: 2rem;
    margin-top: 0;
  }
  
  .content-container-about {
    flex: 1;
    padding-right: 2rem;
  }
  
  .image-container-about {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .tutorial-image-course-about {
    max-width: 70%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    margin-left: 0px;
  }
  
  .section-title222-about {
    font-size: 3rem;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 30px; /* Adds padding at the top to move the title down */
  }
  
  .section-subtitle-about {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 2rem;
    text-align: center;
    padding-top: 20px; /* Adds padding at the top to move the subtitle down */
  }


  
  .section-title222-about2 {
    font-size: 3rem;
    color: black;
    margin-bottom: 1rem;
    text-align: left;
    padding-top: 0px; /* Adds padding at the top to move the title down */
  }
  
  .section-subtitle-about2 {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 2rem;
    text-align: left;
    padding-top: 20px; /* Adds padding at the top to move the subtitle down */
  }
  
  
  .course-boxes-about {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1.5rem;
  }
  
  .course-box {
    padding: 1rem 3rem;
    border-radius: 50px;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    width: fit-content;
  }
  
  /* Specific box colors */
  .course10 {
    background-color: #4CAF50;
    color: white;
  }
  
  .course20 {
    background-color: #FFC107;
    color: rgb(0, 0, 0);
  }
  
  .course30 {
    background-color: #f44336;
    color: white;
  }
  
  /* Remove hover effects */
 
  
  
  /* Third Section: Manager's Account */
  .section-3-about {
    background: 
    url('https://www.transparenttextures.com/patterns/cubes.png'),
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%);
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .manager-content-container-about {
    text-align: center;
    color: white;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title-manager-about {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  
  /* Subtitle boxes layout */
  .ManagerSubtitles-about {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    margin-bottom: 2rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .subtitle-box-about {
    background-color: #FF6F61;
    color: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    overflow-wrap: break-word;
  }
  
  .subtitle-box-about p {
    margin: 0;
    font-size: 1.1rem;
  }
  
  /* Manager feature layout with two boxes per row */
  .manager-features-about {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 100%;
    margin-bottom: 2rem;
  }
  
  .feature-about {
    flex: 1 1 calc(50% - 2rem);
    min-width: 250px;
    max-width: 450px;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-size: 1.3rem;
    border: 2px solid black;
  }
  
  .feature-about h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .feature-about p {
    font-size: 1.3rem;
    color: black;
    text-align: left;
  }
  
  .ManagerAccountButtonHomePage-about {
    padding: 15px 30px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
  }
  
  .ManagerAccountButtonHomePage-about:hover {
    background-color: rgb(51, 51, 51);
    transform: translateY(-3px);
  }
  
  .button-container-about {
    text-align: center;
    margin-top: 2rem;
  }
  
  /* Fourth Section: Standard Account with dotted background */
  .section4-about {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%);
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .content-wrapper-section4-about {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: white;
  }
  
  .content-container-section4-about {
    flex: 1;
    padding-right: 2rem;
  }
  
  .section-title22-section4-about {
    font-size: 2rem;
    color: white;
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Subtitle Box Layout */
  .section-subtitle-section4-about {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .subtitle-box-section4-about {
    color: #005f73;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 1100px;
  }
  
  /* Boxes styling */
  .subtitle-box-section4-about {
    background-color: salmon;
  }
  
  /* Enroll Button */
  .EnrollButtonStandard-about {
    padding: 15px 30px;
    background-color: #005f73;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 1.5rem;
  }
  
  .EnrollButtonStandard-about:hover {
    background-color: rgb(251, 175, 166);
    transform: translateY(-3px);
  }
  
  .button-container-enroll-about {
    text-align: center;
    margin-top: 2rem;
  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 1024px) {
    .content-wrapper-about {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .content-container-about {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  
    .section-title222-about,
    .section-subtitle-about {
      text-align: center;
    }
  
    .course-buttons-about {
      align-items: center;
    }
  
    .ManagerSubtitles-about {
      max-width: 600px;
    }
  
    .feature-about {
      max-width: calc(50% - 1rem);
    }
  
    .subtitle-box-section4-about {
      width: calc(50% - 1rem);
    }
  
    .EnrollButtonStandard-about {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 768px) {
    .section-title222-about {
      font-size: 2.5rem;
    }
  
    .subtitle-box-section4-about {
      width: 80%;
      padding: 20px 40px;
    }
  
    .EnrollButtonStandard-about {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .section-title222-about {
      font-size: 2rem;
    }
  
    .subtitle-box-section4-about {
      width: 90%;
      padding: 20px 20px;
    }
  
    .EnrollButtonStandard-about {
      font-size: 0.875rem;
      padding: 8px 15px;
    }
  
    .ManagerSubtitles-about {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
    }
  
    .feature-about h3 {
      font-size: 1.1rem;
    }
  
    .feature-about p {
      font-size: 0.85rem;
    }
  
    .section-title-manager-about {
      font-size: 1.5rem;
    }
  }
  
/* Responsive adjustments for subtitle boxes */
@media (max-width: 768px) {
  .subtitle-box-about {
    width: 80%;
    padding: 10px;
  }

  .subtitle-box-about p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .subtitle-box-about {
    width: 90%;
    padding: 8px;
  }

  .subtitle-box-about p {
    font-size: 0.9rem;
  }
}
