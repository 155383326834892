/* ManagersPortal.css */
:root {
  --primary-color: #005f73;
  --light-background: #f4f4f9;
  --pink-color: rgb(251, 206, 201);
  --yellow-color: #f9ca63;
  --dark-teal: #006d77;
  --white-color: #f0f8ff;
  --border-color: #ccc;
}

/* Wrapper for the entire Manager's Portal page */
.managers-portal-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--primary-color);
  border-radius: 8px;
  background-size: 150px 150px, cover;
  border: 2px solid var(--border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Title styling */
.managers-portal-title {
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 3rem;
}

/* Grid layout for the portal boxes */
.portal-boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  margin: 20px 0;
  justify-content: center;
}

/* Individual box styling */
.portal-box {
  border: 1px solid black;
  padding: 20px;
  border-radius: 8px;
  width: 270px;
  height: 190px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}



.portal-box-large {
  border: 1px solid black;
  padding: 20px;
  border-radius: 8px;
  width: 270px;
  height: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Logo upload form */
.upload-logo-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

/* File input styling */
.file-input {
  margin-bottom: 0px;
}

/* Button styling */
.portal-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.portal-button:hover {
  background-color: rgb(66, 64, 64);
}

/* Success message styling */
.success-message, .error-message {
  color: black !important;
  font-size: 14px;
  margin-top: 0px;
  text-align: center;
}

/* Delete button styling */
.delete-logo-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Popup styling */
.link-employee-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  max-width: 500px;
}

.popup-content {
  text-align: center;
}

.popup-close-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.popup-close-button:hover {
  background-color: #00a4ce;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .managers-portal-container {
    padding: 1.5rem;
    max-width: 90%;
  }

  .portal-boxes {
    grid-template-columns: repeat(2, 1fr);
  }

  .managers-portal-title {
    font-size: 2.5rem;
  }

  .portal-box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .portal-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .managers-portal-container {
    padding: 1rem;
    max-width: 100%;
  }

  .portal-boxes {
    grid-template-columns: 1fr;
  }

  .managers-portal-title {
    font-size: 2rem;
  }

  .portal-box {
    width: 100%;
    padding: 15px;
  }

  .portal-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }

  .delete-logo-button {
    padding: 6px 12px;
  }
}

.info-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: 10px;
  color: #007bff;
}

.info-button:hover {
  color: #0056b3;
}
