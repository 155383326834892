.standard-report-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #02849e url('../assets/cubes.png');
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .standard-report-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  
  .standard-report-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 1rem;
    color: black;
    margin-bottom: 5px;
    display: block;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  input[type="text"]:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .error-text {
    color: #971305;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .success-text {
    color: black;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .submit-button24 {
    padding: 12px 20px;
    font-size: 2rem;
    color: #fff;
    background-color: rgb(252, 85, 67);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .submit-button24:hover {
    background-color: rgb(252, 185, 177);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .standard-report-container {
      padding: 15px;
      margin: 30px auto;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 10px 15px;
    }
  }
  
  /* Additional CSS for the Dropdown Menu */
  select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    background-color: #fff;
  }
  
  select:focus {
    border-color: #007bff;
    outline: none;
  }
  .warning-text {
    color: rgb(159, 1, 1);
    font-weight: bold;
    margin-top: 5px;
  }
  