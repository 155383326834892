:root {
    --primary-color: #005f73;
    --light-background: #f4f4f9;
    --text-color: #000000;
    --border-color: #ccc;
  }
  
  .account-type-container {
    width: 90%;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  .account-type-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 3rem !important;
  }
  
  .account-options {
    display: flex;
    justify-content: center; /* Center the boxes */
    flex-wrap: wrap;
    gap: 60px; /* Reduce the gap to bring them closer */
}

  
  .account-box {
    background-color: #fff;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .account-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .account-box-title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--primary-color);
  }
  
  .account-box-subtitle {
    font-size: 1rem;
    color: #000000;
    margin-bottom: 15px;
  }
  
  .account-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000;
  }
  
  .account-subtext {
    font-size: 0.875rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .account-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  
  .account-button:hover {
    background-color: #555;
  }
  
  .account-button.request-demo {
    background-color: #000;
  }
  
  .account-benefits-list {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
  }
  
  .account-benefits-list li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .account-benefits-list li::before {
    content: '✔️';
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 10px;
  }
  