/* Progress.css */
.progress-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    background: #006073 url('../../assets/cubes.png');
    border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .progress-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: white;
    margin-bottom: 40px;
  }
  
  .progress-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .employee-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .employee-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }
  
  .employee-name {
    font-size: 1.8rem;
    color: #2980b9;
    margin-bottom: 15px;
  }
  
  .course-progress p {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .completed {
    color: #1a6e3d; /* Green for completed */
  }
  
  .incomplete {
    color: #8b382f; /* Red for incomplete */
  }
  
  @media (max-width: 768px) {
    .progress-container {
      padding: 20px;
    }
  }
  