/* General reset for margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

}

body {
  background-color: aliceblue; /* Your background color */
}

/* Main container styling to avoid margins */
.main-container-content-home2 {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Video and section styling */
.section {
  width: 100%;
  margin: 0;
  padding: 0;
}

.toggle-subtitles-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #005f73;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-subtitles-button:hover {
  background-color: #003f50;
}



.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.section-1-home2 {
  margin: 0;
  padding: 0;
  background: 
  url('../assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, black 20%, black); /* Gradual two-tone background */
}


/* SVG Section */
.svg-section-1-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: -4px; /* Ensure there's no gap */
}

.intro-video-wrapper-home2 {
  position: relative;
  display: inline-block;
}

.intro-video-home2 {
  width: 80%; /* Ensure it takes the full width */
  max-width: 1200px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Second Section: Courses */
.section-2-home2 {
  background-color: #ffeadf;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-wrapper-home2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
  text-align: center; /* Ensure centering on smaller screens */
}

.content-container-home2 {
  flex: 1;
  padding-right: 2rem;
}

.image-container-home2 {
  flex: 1;
  display: flex;
  justify-content: center;
}

.tutorial-image-course2 {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin-left: 0px;
}

.section-title222-home2 {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center; /* Align to center for consistency */
  margin-top: 20px;
}

.section-subtitle-home2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.course-buttons-home2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the buttons */
  gap: 1.5rem;
}

.course-button {
  padding: 1rem 3rem;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.course1 {
  background-color: #255c27;
  color: white;
}

.course2 {
  background-color: #FFC107;
  color: rgb(0, 0, 0);
}

.course3 {
  background-color: #f44336;
  color: white;
}

.course-button:hover {
  opacity: 0.9;
}

/* SVG Section */
.svg-section-2-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-top: -4px;
}

/* Responsive layout for smaller screens */
@media (max-width: 1024px) {
  .content-wrapper-home2 {
      flex-direction: column; /* Stacks content and image on top of each other */
      align-items: center;
      text-align: center;
  }

  .content-container-home2 {
      padding-right: 0;
      margin-bottom: 2rem;
  }

  .section-title222-home2,
  .section-subtitle-home2 {
      text-align: center;
  }

  .course-buttons-home2 {
      align-items: center;
  }
}

@media (max-width: 768px) {
  .section-title222-home2 {
      font-size: 2.5rem;
  }

  .section-subtitle-home2 {
      font-size: 1.2rem;
  }

  .course-button {
      font-size: 1rem;
      padding: 1rem 2rem;
  }
}

@media (max-width: 480px) {
  .section-title222-home2 {
      font-size: 2rem;
  }

  .section-subtitle-home2 {
      font-size: 1rem;
  }

  .course-button {
      font-size: 0.875rem;
      padding: 0.75rem 1.5rem;
  }
}





/* SVG Section for above the third section */
.svg-section-3-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-top: -3px;
  background-color: #ffeadf; /* Ensure the background color matches Section 2 */
}

/* Third Section: Manager's Account */

.section-3-home2 {
  background: 
  url('../assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.manager-content-container-home2 {
  text-align: center;
  color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title-manager-home2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

/* Subtitle boxes layout */
.ManagerSubtitles-home2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows items to wrap when the page gets smaller */
  gap: 2rem;
  margin-bottom: 2rem;
  max-width: 900px; /* Ensures three boxes per row on large screens */
  margin-left: auto;
  margin-right: auto;
}

.subtitle-box-home2 {
  background-color: #FF6F61;
  color: white;
  padding: 5px 2px; /* Increased top and bottom padding */
  border-radius: 10px;
  width: calc(30% - 1rem); /* Reduced width for a more compact look */
  text-align: center;

  font-weight: bold;
}

.subtitle-box-home2 p {
  margin: 0;
  font-size: 1.1rem; /* Slightly increased text size */
}

/* Manager feature layout with two boxes per row */
.manager-features-home2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;
  margin-bottom: 2rem;
}

.feature-home2 {
  flex: 1 1 calc(50% - 2rem); /* Two boxes per row */
  min-width: 250px;
  max-width: 450px; /* Ensures two feature boxes per row */
  background-color: white;
  padding: 0.8rem;
 border-radius: 20px;
}

.feature-home2 h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.feature-home2 p {
  font-size: 1rem;
  color: #FF6F61;
}

.ManagerAccountButtonHomePage-home2 {
  padding: 15px 30px;
  background-color: #FF6F61;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  margin-top: 2rem;
}

.ManagerAccountButtonHomePage-home2:hover {
  background-color: #FF856B;
  transform: translateY(-3px);
}

.button-container-home2 {
  text-align: center;
  margin-top: 2rem;
}

.manager-account-video-container {
  margin: 2rem 0;
  text-align: center;
}

.manager-account-video {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}


/* Responsive layout for subtitle boxes and features */
@media (min-width: 1024px) {
  /* Ensures three subtitle boxes per row on larger screens */
  .ManagerSubtitles-home2 {
      max-width: 900px; /* Allows three boxes to fit side by side */
      margin-left: auto;
      margin-right: auto;
  }
}

@media (max-width: 1024px) {
  /* Stacks the subtitle boxes into two rows on medium screens */
  .ManagerSubtitles-home2 {
      max-width: 600px; /* Allows two boxes per row */
  }

  /* Two features per row on medium screens */
  .feature-home2 {
      max-width: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  /* Stacks the subtitle boxes vertically and centers them on smaller screens */
  .ManagerSubtitles-home2 {
      flex-direction: column;
      align-items: center; /* Centers the boxes */
      max-width: 100%;
  }

  .subtitle-box-home2 {
      width: 80%; /* Make the boxes narrower on small screens */
      padding: 40px 20px; /* Increase top and bottom padding */
  }

  .feature-home2 {
      flex: 1 1 100%; /* Stacks features vertically on small screens */
  }

  .feature-home2 h3 {
      font-size: 1.3rem;
  }

  .feature-home2 p {
      font-size: 0.9rem;
  }

  .section-title-manager-home2 {
      font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .subtitle-box-home2 {
      width: 90%; /* Make the boxes more compact on very small screens */
      padding: 50px 20px; /* Further increase padding for a taller look */
  }

  .ManagerAccountButtonHomePage-home2 {
      padding: 12px 25px;
      font-size: 1rem;
  }

  .section-title-manager-home2 {
      font-size: 1.5rem;
  }

  .feature-home2 h3 {
      font-size: 1.1rem;
  }

  .feature-home2 p {
      font-size: 0.85rem;
  }
}




/* Adjust SVG Section for above the fourth section */
.svg-section-4-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-top: -4px; /* Ensures smooth transition between sections */
  background-color: #005f73; /* Fill the gap with the desired color */

}

/* Fourth Section: Standard Account */
.section4-home2 {
  background-color: #ffeadf;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-wrapper-section4-home2 {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: white;
}

.section-title22-section4-home2 {
  font-size: 2rem;
  color: black;
  margin-bottom: 2rem;
}

.section-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.card-section4-home2 {
  background-color: #005f73;
  color:  white;
  padding: 5px;
  border-radius: 0px;
  width: 300px;
  text-align: center;

  transition: transform 0.3s ease;
}

.card-icon {
  margin-bottom: 10px;
}

.card-icon img {
  width: 50px;
  height: 50px;
}

@media (max-width: 768px) {
  .section-title22-section4-home2 {
    font-size: 1.75rem;
  }

  .card-section4-home2 {
    width: 100%;
    max-width: 300px;
  }
}
