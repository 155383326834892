body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  height: auto; /* Make height flexible */
  min-height: 100vh; /* Ensure the page covers full height */
  background: url('../../assets/cubes.png'), linear-gradient(90deg, black 20%, black 80%);
  
}

.user-portal {
  padding: 20px;
  background: var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  text-align: center;
  margin-top: 1rem; /* Add top margin to avoid overlap with the top bar */
  border: 2px solid var(--border-color);
}

.user-portal h2 {
  font-size: 2.5rem; /* Adjust for better responsiveness */
  color: white;
  margin-bottom: 20px;
}

.course-container2, .course-container3, .course-container4 {
  margin-bottom: 30px;
  background-color: black;
  border-radius: 8px;
  padding: 20px;
}

.course-section2, .course-section3, .course-section4 {
  border: 0;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: black;
  color: white;
  text-align: center;
}

.course-section2 h3, .course-section3 h3, .course-section4 h3 {
  font-size: 1.5rem; /* Adjust for better responsiveness */
  color: #ffffff;
  margin-bottom: 10px;
}

.stars {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.star {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 3px;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.star.filled {
  background-color: gold;
}

.star.outlined {
  background-color: transparent;
  border: 2px solid gold;
}

.report-button,
.settings-button2,
.certificate-button,
.upgrade-button {
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  border: none;
}

.report-button {
  background-color: black;
  color: white;
}

.report-button:hover {
  background-color: rgb(45, 45, 45);
}

.settings-button2 {
  background-color: black;
  color: white;
  margin-bottom: 10px;
}

.settings-button2:hover {
  background-color: rgb(45, 45, 45);
}

.certificate-button {
  background-color: green;
  color: white;
}

.certificate-button:hover {
  background-color: #45a049;
}

.upgrade-button {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  padding: 12px 24px; /* Padding around the text */
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  margin-top: 20px; /* Space above the button */
}

.upgrade-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.upgrade-button:active {
  background-color: #3e8e41; /* Even darker green when pressed */
  box-shadow: 0 5px #666; /* Button shadow when pressed */
  transform: translateY(4px); /* Slight downward movement when pressed */
}

.downgrade-date {
  color: white;
  margin-top: 15px;
  font-weight: bold;
  
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .user-portal {
    margin-top: 60px; /* Adjust to prevent overlap */
    padding: 15px;
  }

  .user-portal h2 {
    font-size: 2rem;
  }

  .course-section2 h3, .course-section3 h3, .course-section4 h3 {
    font-size: 1.3rem;
  }

  .report-button,
  .settings-button2,
  .certificate-button,
  .upgrade-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .profile-container {
    padding: 10px; /* Increase padding for mobile spacing */
  }
}

@media (max-width: 480px) {
  .user-portal {
    margin-top: 50px;
    padding: 10px;
  }

  .user-portal h2 {
    font-size: 1.8rem;
  }

  .course-section2 h3, .course-section3 h3, .course-section4 h3 {
    font-size: 1.2rem;
  }

  .report-button,
  .settings-button2,
  .certificate-button,
  .upgrade-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }

  .profile-container {
    padding: 10px;
  }
}

.footer {
  margin-top: 30px; /* Space between content and footer */
  padding-bottom: 20px; /* Add padding to prevent overlap */
}
