/* General container styling */
.cybersecurity-basics-container-threat-landscape {
    font-family: 'Roboto', sans-serif;
    background: 
    url('../assets/cubes.png'), /* Local path to cubes.png */
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  }
  
  .module-section-threat-landscape {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .inline-content-threat-landscape {
    text-align: center;
    margin-bottom: 40px;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 8px;
  }

  .image-source-threat-landscape {
    color: black !important;
  }
  
  .image-source-threat-landscape a {
    color: black !important; /* Ensure the link is also black */
  }
  
  
  .inline-content-threat-landscape h2 {
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  
  .inline-content-threat-landscape h4 {
    color: black !important;
  }
  
  .divider-threat-landscape {
    height: 1px;
    background-color: #ccc;
    margin: 40px 0;
    width: 100%;
  }
  
  /* Image styling */
  .small-image-threat-landscape {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-source-threat-landscape {
    text-align: center;
    font-style: italic;
    font-size: 14px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .image-source-threat-landscape a {
    color: #007bff;
    text-decoration: underline;
  }
  
  /* Bordered container styling */
  .bordered-container-threat-landscape {
    padding: 30px;
    margin-bottom: 50px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .bordered-container-threat-landscape ul {
    font-size: 16px;
    line-height: 1.7;
  }
  
  .bordered-container-threat-landscape li {
    margin-bottom: 15px;
  }
  
  /* Figure heading */
  .figure-heading-threat-landscape {
    text-align: center;
    margin-bottom: 20px;
    color: salmon;
    font-size: 20px;
  }
  
  /* Button styling */
  .start-quiz-button-container-threat-landscape {
    text-align: center;
    margin-top: 40px;
  }
  
  .next-button-threat-landscape {
    background-color: salmon;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .next-button-threat-landscape:hover {
    background-color: rgb(251, 176, 168);
  }
  
  /* Centered link styling */
  .centered-link-threat-landscape {
    text-align: center;
    padding: 20px;
    color: black;
    margin-top: 40px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .centered-link-threat-landscape a {
    color: #007bff;
    text-decoration: underline;
  }
  