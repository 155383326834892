:root {
  --primary-color: #005f73;
  --hover-color: #0a9396;
  --light-background: #f0f8ff;
  --white: #ffffff;
  --dark-text: #333;
  --gradient-start: #fa8072;
  --gradient-end: #ff6347;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Ensure the user's name is not underlined and is displayed as black */
.user-name-link {
  text-decoration: none; /* Remove the underline */
  color: var(--dark-text); /* Set text color to black */
  font-weight: bold;
}

.user-name-link:hover {
  text-decoration: none; /* Ensure no underline on hover */
  color: var(--dark-text); /* Keep text color as black on hover */
}

.topbar-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
  padding: 1vh 2vw;
  position: relative;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
}



.manager-logo {
  max-width: 15vw;
  max-height: 8vh;
  object-fit: contain;
}

.topbar-new h1 {
  margin: 0;
  font-size: calc(1.5rem + 1vw);
  color: var(--white);
  background: linear-gradient(to right, var(--white), var(--white));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 1vh 0.5vw;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.15vw;
  white-space: nowrap; /* Prevents text from wrapping onto new lines */
  
  
  
}

.auth-buttons-new {
  display: flex;
  gap: 1vw;
}

.signup-button-new,
.login-button-new,
.logout-button-new {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 1vh 2vw;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap; /* Prevents the button text from wrapping */
}

.signup-button-new:hover,
.login-button-new:hover,
.logout-button-new:hover {
  background-color: var(--hover-color);
  box-shadow: 0.4vw 0.4vw 0.8vw rgba(0, 0, 0, 0.6);
}

/* Burger Icon */
.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
 
}

.burger-icon .line {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}


/* Responsive Navigation */
.navigation-new {
  display: flex;
  align-items: center;
  background-color: #ffeadf;
  padding: 1vh 2vw;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
}

.navigation-new a {
  color: var(--dark-text);
  text-decoration: none !important;
  margin: 0 1vw;
  font-family: 'Poppins', sans-serif;
  font-size: calc(0.875rem + 0.2vw);
  line-height: 1.25rem;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 0.15vw solid transparent;
  display: flex;
  align-items: center;
  height: 100%;
}

.navigation-new a:hover,
.navigation-new .active-link {
  color: black; /* Change the text color to black */
  border-bottom: 0.15vw solid black; /* Change the underline border to black */
}


/* Burger menu open styles */
.menu-open {
  transform: translateX(0%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 49px;
  right: 0;
  width: 100%;
  background-color: var(--light-background);
  padding: 2rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.menu-open a {
  padding: 10px;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  display: block;
}

.menu-open .auth-links {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.menu-open .auth-links a {
  padding: 10px;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  display: block;
  color: var(--dark-text);
}

/* Hide auth links on large screens */
@media (min-width: 769px) {
  .auth-links {
    display: none;
  }
}

/* Show auth links on small screens */
@media (max-width: 768px) {
  .auth-links {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .auth-links a {
    padding: 10px;
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
    display: block;
    color: var(--dark-text);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .burger-icon {
    display: flex;
  }

  .navigation-new {
    display: none;
  }

  /* Hide auth buttons on small screens */
  .auth-buttons-new {
    display: none;
  }

  .menu-open {
    display: block;
  }
}

@media (max-width: 480px) {
  .topbar-new {
    flex-direction: column;
    padding: 2vh 4vw;
  }

  .manager-logo {
    max-width: 25vw;
  }

  .topbar-new h1 {
    font-size: calc(1.2rem + 1vw);
  }

  .signup-button-new,
  .login-button-new,
  .logout-button-new {
    padding: 1vh 4vw;
  }

  .menu-open a {
    font-size: 1rem;
  }
}
/* Hide burger icon on large screens */
@media (min-width: 769px) {
  .burger-icon {
    display: none !important;
  }
}

/* Show burger icon on smaller screens */
@media (max-width: 768px) {
  .burger-icon {
    display: flex;
  }
}
