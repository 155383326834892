:root {
  --primary-color: #005f73;
  --hover-color: #0a9396;
  --light-background: #f4f4f9;
  --text-color: white;
  --border-color: #ccc;
}

/* General container styling */
.cybersecurity-basics-container {
  padding: 1rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1000px;
  width: 90%; /* Make sure it scales for different screen sizes */
  text-align: left;
  position: relative;
}

/* Header styling */
.cybersecurity-basics-header {
  text-align: center;
  margin-bottom: 1rem;
}

.cybersecurity-basics-header h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

/* Video container */
.video-container {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: auto;
  max-width: 100%;
}

/* Module content */
.module-section {
  margin-bottom: 1rem;
}

.module-content h3 {
  font-size: 1.5rem;
  color: salmon;
}

.module-content p {
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
}

/* Highlighted section */
.highlighted-section {
  border: 4px solid salmon;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.highlighted-section h3 {
  color: white;
}

/* Start quiz button */
.start-quiz-button-container {
  text-align: center;
  margin-top: 1rem;
}

.start-quiz-button {
  display: inline-block;
  width: 100%;
  max-width: 300px; /* Limit button size on larger screens */
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.start-quiz-button:hover {
  background-color: rgb(37, 138, 171);
}

.warning-text {
  color: rgb(172, 0, 0);
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}

/* Back button */
.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--hover-color);
}

/* Responsive behavior */
@media (max-width: 768px) {
  .cybersecurity-basics-container {
    width: 95%; /* Slightly more compact for tablets */
  }

  .module-content h3 {
    font-size: 1.25rem; /* Slightly smaller for tablets */
  }

  .start-quiz-button {
    font-size: 1rem; /* Slightly smaller button text */
  }

  .back-button {
    width: 100%;
    max-width: 300px; /* Make back button full-width on smaller screens */
  }
}

@media (max-width: 480px) {
  .cybersecurity-basics-header h2 {
    font-size: 1.5rem; /* Reduce title size for mobile */
  }

  .module-content h3 {
    font-size: 1.15rem; /* Smaller headings on mobile */
  }

  .module-content p {
    font-size: 0.9rem; /* Smaller text for mobile */
  }

  .start-quiz-button {
    font-size: 0.9rem; /* Smaller button text */
    padding: 0.5rem;
  }

  .back-button {
    font-size: 0.9rem; /* Smaller back button */
  }

  .highlighted-section h3 {
    font-size: 1rem;
  }
}
