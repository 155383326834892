:root {
  --primary-color: #005f73;
  --light-background: #f4f4f9;
  --pink-color: rgb(251, 206, 201);
  --yellow-color: #f9ca63;
  --dark-teal: #006d77;
  --white-color: #f0f8ff;
}

/* Introduction Container */
.introduction-container2 {
  padding: 2rem;
  background: 
   /* Local path to cubes.png */
  linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1000px;
  text-align: left;
  margin-left: 90px !important;
  margin-right: 0px !important;
}

/* Story Introduction */
.story-introduction {
  margin-bottom: 2rem;
}

.story-introduction h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.video-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Progress Bar */
.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e0e0e0;
}

.progress-bar {
  height: 5px;
  background-color: var(--primary-color);
  width: 0%; /* Initial width is 0% */
  transition: width 0.1s linear; /* Smooth transition for progress bar */
}

/* Start Button */
.start-button-container-intro {
  text-align: center;
  margin-top: 1rem;
}

.start-button-intro1 {
  width: auto;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.start-button-intro1:hover {
  background-color: #009dbd;
}

/* Back Button */
.back-button-intro {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button-intro:hover {
  background-color: blue;
}

/* Warning Text */
.warning-text {
  color: rgb(144, 0, 0);
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.warning-text22 {
  color: rgb(104, 3, 3);
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}
/* Logo Overlay */
.logo-overlay-intro1 {
  position: absolute;
  top: 10%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
  z-index: 10; /* Make sure it's above the video */
}

.logo-image-intro1 {
  max-width: 150px; /* Adjust logo size for responsive scaling */
  opacity: 0.8; /* Slightly transparent if desired */
}

/* Media Queries for Responsiveness */

/* For screens smaller than 768px (tablets, phones) */
@media (max-width: 768px) {
  .introduction-container2 {
    width: 95%;
    margin: 1rem auto;
    padding: 1.5rem;
  }

  .story-introduction h2 {
    font-size: 1.5rem;
  }

  .start-button-intro1 {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .video-container {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .logo-image-intro1 {
    max-width: 100px; /* Reduce logo size on smaller screens */
  }
}

/* For screens smaller than 480px (phones) */
@media (max-width: 480px) {
  .introduction-container2 {
    width: 100%;
    padding: 1rem;
  }

  .story-introduction h2 {
    font-size: 1.25rem;
  }

  .start-button-intro1 {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .logo-image-intro1 {
    max-width: 80px; /* Further reduce logo size on smaller screens */
  }
}
