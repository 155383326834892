:root {
    --primary-color: #005f73;
    --hover-color: #0a9396;
    --light-background: #f4f4f9;
    --dark-text: #333333;
    --white: #ffffff;
  }
  
  /* Container for the login form */
  .login-containerLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    padding: 1rem;
    background: 
    url('../assets/cubes.png'), /* Local path to cubes.png */
    linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
  }
  

  .login-containerLogin h1 {
    color: black;
    margin-bottom: 2rem !important;
  }

  /* Login form box */
  .auth-boxLogin {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  /* Back button */
  .back-buttonLogin {
    display: block;
    margin: 0 auto 20px auto;
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .back-buttonLogin:hover {
    background-color: var(--hover-color);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
  }
  
  /* Error message */
  .error-containerLogin {
    margin-bottom: 20px;
  }
  
  .error-textLogin {
    color: rgb(148, 0, 0);
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  /* Reset password button */
  .reset-password-buttonLogin {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .reset-password-buttonLogin:hover {
    background-color: var(--hover-color);
    
  }
  
  /* Input field groups */
  .input-groupLogin {
    margin-bottom: 20px;
  }
  
  .input-groupLogin label {
    display: block;
    font-size: 14px;
    color: var(--dark-text);
    margin-bottom: 5px;
  }
  
  .input-groupLogin input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Login button */
  .login-buttonLogin {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-buttonLogin:hover {
    background-color: var(--hover-color);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
  }
  
  /* Responsive media queries */
  @media (max-width: 768px) {
    .auth-boxLogin {
      padding: 30px;
      max-width: 350px;
    }
  
    .back-buttonLogin {
      padding: 8px 16px;
    }
  
    .login-buttonLogin, .reset-password-buttonLogin {
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .auth-boxLogin {
      padding: 20px;
      max-width: 300px;
    }
  
    .back-buttonLogin {
      padding: 6px 12px;
    }
  
    .login-buttonLogin, .reset-password-buttonLogin {
      padding: 6px 12px;
    }
  }
 /* ReCAPTCHA container styling to ensure it fits within the auth-box */
.recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  transform: scale(0.9); /* Adjust scale to make it smaller */
  transform-origin: center center;  /* Ensure scaling from the center */
  max-width: 100%; /* Ensure it stays within the container */

}

@media (max-width: 600px) {
  .recaptcha-container {
    transform: scale(0.8); /* Further reduce the size on smaller screens */
  }
}
