/* Container and basic layout */
.safe-browsing-game-container-unique11 {
  padding: 2rem;
  background: 
  url('../../assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 900px;
  text-align: center;
  position: relative;
  padding-top: 30px; /* Keep content moved down */
}

/* Heading */
.safe-browsing-game-container-unique11 h2 {
  margin-bottom: 1.5rem;
}

/* Buttons styling */
.safe-browsing-game-container-unique11 button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.safe-browsing-game-container-unique11 button:hover {
  background-color: rgb(37, 138, 171);
}

/* Feedback styling */
.feedback-unique11 {
  margin-top: 1rem;
  font-size: 1.2rem;
}

.correct-unique11 {
  color: green;
}

.incorrect-unique11 {
  color: red;
}

.correct-feedback-unique11 {
  color: green;
}

.incorrect-feedback-unique11 {
  color: red;
}

/* Back arrow button styling at the bottom center */
.back-arrow-button-unique11 {
  position: relative;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent !important;
  color: black !important;
  padding: 10px 20px !important;
  font-size: 40px !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .back-arrow-button-unique11 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .back-arrow-button-unique11 {
    font-size: 16px;
  }
}

.falling-star {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px; /* ⬆ Increased size */
  height: 80px;
  background-color: rgb(203, 203, 3); /* Star color */
  clip-path: polygon(
    50% 0%, 61% 35%, 98% 35%, 
    68% 57%, 79% 91%, 50% 70%, 
    21% 91%, 32% 57%, 2% 35%, 
    39% 35%
  ); /* Keeps star shape */
  animation: fall 2s linear forwards; /* Ensures it disappears */
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(180deg) scale(1.5); /* ⬆ Slight scale effect */
    opacity: 0;
  }
}



/* Correct/Incorrect button colors */
.correct-unique11 {
  background-color: #4caf50;
  color: white;
}

.incorrect-unique11 {
  background-color: #f44336;
  color: white;
}

.feedback-unique11 {
  margin-top: 10px;
  font-size: 1.2rem;
}

.correct-feedback-unique11 {
  color: #4caf50;
}

.incorrect-feedback-unique11 {
  color: #f44336;
}


.BasicsTitle {
  font-size: 3rem !important;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0;
}
