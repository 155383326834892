:root {
    --primary-color: #005f73;
    --light-background: #f4f4f9;
    --section-background-course-one: #ffeadf;
    --section-background-course-two: #005f73;
    --section-background-course-three: #91c891;
    --section-text-color: #ffffff;
}

/* General Styles */
body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    background-color: var(--light-background);
    overflow-x: hidden;
}

.section {
    position: relative;
    width: 100%;
    padding: 4rem 2rem;
    box-sizing: border-box; /* Ensure padding is inside the element width */
}

/* First Section */
.section-courses {
    text-align: center;
    min-height: 300px; 
}

.section-title-courses {
    font-size: calc(2rem + 1vw); /* Adjusted for better scaling */
    color: white;
    text-align: left;
    margin-top: 3rem;
    margin-left: 15px;
}

/* Subtitle Container */
.section-subtitle-container {
    border-radius: 10px;
    padding: 20px;
    max-width: 1000px;
    width: 100%; 
    text-align: left;
    font-size: calc(0.75rem + 1vw); /* Adjust for better scaling */
    color: white;
}

/* Course One Section */
.section-2-courses {
    background: linear-gradient(to bottom, #ffeadf, #ffc2a1); 
    min-height: 300px; 
    position: relative;
}

.content-wrapper-course-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem;
    flex-wrap: wrap; /* Allows the content to wrap for better responsiveness */
}

.image-container-course-one {
    flex: 1;
    display: flex;
    justify-content: center;
}

.tutorial-image-course-one {
    max-width: 350px;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 40px;
}

.content-container-course-one {
    flex: 1;
    text-align: left;
}

.course-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px; /* Spacing between each point */
    font-size: 18px;
    color: black;
}

.course-summary-container2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px; /* Spacing between each point */
    font-size: 18px;
    color: white;
}

.course-summary-item {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 15px; /* Space between the tick and the text */
}

.checkmark {
    font-size: 22px;
    color: #8A2BE2; /* Purple checkmark */
    flex-shrink: 0; /* Prevents shrinking */
}

.course-summary-item::before {
   
    font-size: 20px;
    margin-right: 10px;
    
}


.section-title-course-one {
    font-size: calc(1.5rem + 0.5vw); /* Adjusted for better scaling */
    color: var(--primary-color);
    margin-bottom: 2rem;
    margin-top: 30px;
}

.section-subtitle-course-one {
    font-size: calc(1rem + 0.5vw);
    color: var(--primary-color);
    margin-bottom: 2rem;
}

.begin-button-course-one {
    background-color: black;
    color: white;
    padding: 1rem 2rem;
    font-size: calc(1rem + 0.5vw);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.begin-button-course-one:hover {
    background-color: rgb(48, 48, 48);
}

/* Course Two Section */
.section-3-courses {
    background: linear-gradient(to bottom, #005f73, #003844); 
    color: var(--section-text-color);
    min-height: 310px;
    position: relative;
}

.content-wrapper-course-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem;
    flex-wrap: wrap; /* Enables wrapping */
}

.content-container-course-two {
    flex: 1;
    text-align: left;
}

.image-container-course-two {
    flex: 1;
    display: flex;
    justify-content: center;
}

.tutorial-image-course-two {
    max-width: 350px;
    height: auto;
    border-radius: 10px;
    margin-right: 70px;
    margin-left: 20px;
}

.section-title-course-two {
    font-size: calc(1.5rem + 0.5vw); /* Adjusted for better scaling */
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
}

.section-subtitle-course-two {
    font-size: calc(1rem + 0.5vw);
    color: #ffffff;
    margin-bottom: 20px;
}

.begin-button-course-two {
    background-color: black;
    color: white;
    padding: 1rem 2rem;
    font-size: calc(1rem + 0.5vw);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
}

.begin-button-course-two:hover {
    background-color: #212121;
}

/* Course Three Section */
.section-4-courses {
    background: linear-gradient(to bottom, #a8d5a3, #567d46); 
    min-height: 520px;
    position: relative;
}

.content-wrapper-course-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem;
    flex-wrap: wrap; /* Enables wrapping */
}

.image-container-course-three {
    flex: 1;
    display: flex;
    justify-content: center;
}

.tutorial-image-course-three {
    max-width: 350px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 80px;
}

.content-container-course-three {
    flex: 1;
    text-align: left;
}

.section-title-course-three {
    font-size: calc(1.5rem + 0.5vw); /* Adjusted for better scaling */
    color: var(--primary-color);
    margin-bottom: 20px;
}

.section-subtitle-course-three {
    font-size: calc(1rem + 0.5vw);
    color: black;
    margin-bottom: 20px;
    width: 100%;
}

.begin-button-course-three {
    background-color: black;
    color: white;
    padding: 1rem 2rem;
    font-size: calc(1rem + 0.5vw);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.begin-button-course-three:hover {
    background-color: rgb(51, 51, 51);
}

/* Responsive Design */
@media (max-width: 768px) {
    .section-title-courses,
    .section-title-course-one,
    .section-title-course-two,
    .section-title-course-three {
        font-size: calc(1.5rem + 2vw);
    }

    .section-subtitle-courses,
    .section-subtitle-course-one,
    .section-subtitle-course-two,
    .section-subtitle-course-three {
        font-size: calc(1rem + 1vw);
    }

    .content-wrapper-course-one,
    .content-wrapper-course-two,
    .content-wrapper-course-three {
        flex-direction: column;
        text-align: center;
    }

    .tutorial-image-course-one,
    .tutorial-image-course-two,
    .tutorial-image-course-three {
        max-width: 80%;
    }

    .section-subtitle-container {
        width: 100%;
        max-width: 95%;
    }

    /* Ensure better margin for buttons on small screens */
    .begin-button-course-one,
    .begin-button-course-two,
    .begin-button-course-three {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 480px) {
    .section-title-courses,
    .section-title-course-one,
    .section-title-course-two,
    .section-title-course-three {
        font-size: 1.5rem;
    }

    .section-subtitle-courses,
    .section-subtitle-course-one,
    .section-subtitle-course-two,
    .section-subtitle-course-three {
        font-size: 1rem;
    }

    .tutorial-image-course-one,
    .tutorial-image-course-two,
    .tutorial-image-course-three {
        max-width: 90%;
        margin: 0 auto 20px;
    }
}
