/* Container and basic layout */
.sorting-game-container {
  padding: 0rem;
  background: 
  url('../../assets/cubes.png'), /* Local path to cubes.png */
  linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1250px;
  text-align: center;
  position: relative; /* Added for positioning */

}

.terms-container {
  margin-bottom: 2rem;
}

.term {
  background-color: var(--primary-color);
  color: black;
  padding: 0.5rem;
  border-radius: 4px;
  margin: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
}

.categories-container {
  display: flex;
  justify-content: space-around;
}

.category {
  background-color: var(--secondary-color);
  padding: 1rem;
  border-radius: 4px;
  width: 45%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed var(--primary-color);
}

.category h3 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.game-complete {
  margin-top: 2rem;
}

.game-complete p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgb(37, 138, 171);
}

/* Back arrow button styling at the top left */
.back-arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-arrow-button:hover {
  color: #005f73;
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}

.good-term {
  color: green;
}

.bad-term {
  color: red;
}

.next-button {
  margin-top: 1rem;
}
.answer-buttons2 {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  color: #ddd !important;
}