:root {
    --primary-color: #005f73;
    --light-background: #f4f4f9;
    --hover-color: #0a9396;
    --white: #ffffff;
    --dark-text: #333333;
  
}

html, body {
    height: 100%; /* Ensure the body and html take up the full height of the viewport */
    margin: 0;
    padding: 0;
    background-color: aliceblue;
    background-size: cover;
}
/* General Container Styles */
.settings-container {
    padding: 40px;
        background: 
        linear-gradient(90deg, var(--primary-color) 20%, #007f98 80%); /* Gradual two-tone background */
        border-radius: 10px;
        max-width: 850px;
        margin: 0 auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 2px solid var(--border-color);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    
}

/* Title */
.settings-title-standard {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 3rem;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }

  .report-threat-button {
    background-color: darkred !important;
    margin-bottom: 1rem;
  }
  
  .confirm-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .back-button-standardSettings {
    padding: 0.5rem 1rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .back-button-standardSettings:hover {
    background-color:  rgb(205, 201, 201);
  }
  
  .cancel-button {
    background-color: gray;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  

/* Card Style */
.settings-card {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    text-align: center;
    margin-top: 10px;
    
}

/* Subheading */
.settings-subheading {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

/* Text */
.settings-text {
    font-size: 1rem;
    color: black !important;
    margin-bottom: 0.5rem;
}

/* Input */
.settings-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 1rem;
}



/* Button */
.settings-button {
    padding: 0.75rem 1.5rem;
    background-color: black;
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-right: 5px;
}

.settings-button:hover {
    background-color: rgb(59, 59, 59);
}

/* Error Message */
.settings-error {
    color: var(--danger-color);
    font-size: 0.9rem;
    margin-top: 1rem;
}

/* Success Message */
.success-text {
    color: #2ecc71;
    font-size: 0.9rem;
    margin-top: 1rem;
}

/* Delete Account Button */
.settings-danger-button {
    background-color:black;
    color: var(--white);
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.settings-danger-button:hover {
    background-color: rgb(48, 47, 47);
}

/* Responsive Design */
@media (max-width: 768px) {
    .settings-container {
        margin-top: 5rem; /* Increase the margin for smaller screens */
        padding: 1.5rem;
    }

    .settings-title {
        font-size: 1.75rem;
    }

    .settings-subheading {
        font-size: 1.25rem;
    }

    .settings-input,
    .settings-button,
    .settings-danger-button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .settings-container {
        margin-top: 6rem; /* Further increase the margin for very small screens */
        padding: 1rem;
    }

    .settings-title {
        font-size: 1.5rem;
    }

    .settings-subheading {
        font-size: 1.1rem;
    }

    .settings-input,
    .settings-button,
    .settings-danger-button {
        font-size: 0.9rem;
        padding: 0.6rem 1.2rem;
    }
}
